
  
  
  import UMUM from '../../library/umum'
  import FETCHING from '../../library/fetching'
  import DATA_MASTER from '../../library/dataMaster'
  
  export default {
    data() {
      return {
  
        form : {
          id : '',
          nik : '',
          nama_lengkap : '',
          tmp_lahir : '',
          tgl_lahir : '',
          jns_kelamin : '',
          alamat : '',
          dusun : '',
          rt_rw : '',
          kecamatan_id : '',
          des_kel_id : '',
          telp_hp : '',
          email : '',
          kode_pos : '',
          status_perkawinan_id : '',
          uraian_perkawinan : '',
          agama_id : '',
          uraian_agama : '',
          tinggi_bdn : '',
          berat_bdn : '',
          status : '',
          keterangan : '',
          no_pendaftaran : '',
          file : null,
  
  
  
          biodata_id : '',
          ditujukan : '',
          jabatan : '',
          lokasi : '',
          lokasi_wilayah : '',
          besaran_upah_id : '',
        },
  
        formPendidikan : {
          biodata_id : '',
        },
  
        formPengalaman : {
          biodata_id : '',
        },
  
        formJabatan : {
          id : '',
          biodata_id : '',
          ditujukan : '',
          jabatan : '',
          lokasi : '',
          lokasi_wilayah : '',
          besaran_upah_id : '',
        },
  
        formLampiran : {
          id : '',
          biodata_id : '',
          uraian : '',
          file : null,
          file_type : '',
        },
  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        listKecamatan : '',
        listDesKel : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
  
  
       
        list_data : [],
        list_pendidikan : [],
        list_pengalaman : [],
        list_jabatan : [],
        list_keterampilan : [],
        list_lampiran : [],
  
        page_first: 1,
        page_last: 0,
        page_limit: 10,
        cari_value : "",
        file_old : "",
        cek_load_data : true,
        file_path: this.$store.state.url.URL_APP + "uploads/",
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_lihat : false,
        mdl_lihat_lampiran : false,
        mdl_setujui : false,
        mdl_tolak : false,
        mdl_print : false,
        btn_add: false,
  
  
        UMUM : UMUM,
        FETCHING : FETCHING,
        DATA_MASTER : DATA_MASTER,
      }
    },
    methods: {
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                // kecamatan_id : this.form.kecamatan_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                // console.log(res_data);
        });
      },
  
      getViewPendidikan : function(idku){
        // console.log(idku)
        this.formPendidikan.biodata_id = idku
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_BIO_PENDIDIKAN_FORMAL + "view_verifikasi", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              biodata_id : this.formPendidikan.biodata_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_pendidikan = res_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
        });
      },
  
      getViewPengalaman : function(idku){
        // console.log(idku)
        this.formPengalaman.biodata_id = idku
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_BIO_PENGALAMAN_KERJA + "view_verifikasi", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              biodata_id : this.formPengalaman.biodata_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_pengalaman = res_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
        });
      },
  
      getViewJabatan : function(idku){
        // console.log(idku)
        this.formJabatan.biodata_id = idku
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_BIO_JABATAN + "view_verifikasi", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              biodata_id : idku,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_jabatan = res_data[0];
              var data = res_data[0];
              this.formJabatan.id = data.id;
              this.formJabatan.biodata_id = data.biodata_id;
              this.formJabatan.ditujukan = data.ditujukan;
              this.formJabatan.jabatan = data.jabatan;
              this.formJabatan.lokasi = data.lokasi;
              this.formJabatan.lokasi_wilayah = data.lokasi_wilayah;
              this.formJabatan.besaran_upah_id = data.besaran_upah_id;
              this.formJabatan.uraian_besaran_upah = data.uraian_besaran_upah;                
              this.$store.commit("hideLoading");
            //   console.log(res_data[0]);
        });
      },
  
      getViewLampiran : function(idku){
      //   console.log(idku)
        this.formLampiran.biodata_id = idku
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_BIO_LAMPIRAN + "view_verifikasi", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              biodata_id : this.formLampiran.biodata_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              this.list_lampiran = res_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
        });
      },
  
      selectData : function(data){
        //   console.log(data)
          this.getViewJabatan(data.id)
          this.form.id = data.id;
          this.form.nik = data.nik;
          this.form.nama_lengkap = data.nama_lengkap;
          this.form.tmp_lahir = data.tmp_lahir;
          this.form.tgl_lahir = data.tgl_lahir;
          this.form.jns_kelamin = data.jns_kelamin;
          this.form.alamat = data.alamat;
          this.form.dusun = data.dusun;
          this.form.rt_rw = data.rt_rw;
          this.form.kecamatan_id = data.kecamatan_id;
          this.form.nama_kecamatan = data.nama_kecamatan;
          this.form.des_kel_id = data.des_kel_id;
          this.form.nama_des_kel = data.nama_des_kel;
          this.form.telp_hp = data.telp_hp;
          this.form.email = data.email;
          this.form.kode_pos = data.kode_pos;
          this.form.status_perkawinan_id = data.status_perkawinan_id;
          this.form.uraian_perkawinan = data.uraian_perkawinan;
          this.form.agama_id = data.agama_id;
          this.form.uraian_agama = data.uraian_agama;
          this.form.tinggi_bdn = data.tinggi_bdn;
          this.form.berat_bdn = data.berat_bdn;
          this.form.status = data.status;
          this.form.keterangan = data.keterangan;
          this.form.file = data.file;
          this.file_old = data.file;
  
          this.form.username = data.username;
          this.form.nama = data.nama;
          this.form.email = data.email;
          this.form.hp = data.hp;
  
  
          // this.formJabatan.id = data.id;
          // this.formJabatan.biodata_id = data.biodata_id;
          // this.formJabatan.ditujukan = data.ditujukan;
          // this.formJabatan.jabatan = data.jabatan;
          // this.formJabatan.lokasi = data.lokasi;
          // this.formJabatan.lokasi_wilayah = data.lokasi_wilayah;
          // this.formJabatan.besaran_upah_id = data.besaran_upah_id;
          // this.formJabatan.uraian_besaran_upah = data.uraian_besaran_upah;
      },
  
      // selectDataJabatan : function(data){
      //     console.log(data)
      //     this.formJabatan.id = data.id;
      //     this.formJabatan.biodata_id = data.biodata_id;
      //     this.formJabatan.ditujukan = data.ditujukan;
      //     this.formJabatan.jabatan = data.jabatan;
      //     this.formJabatan.lokasi = data.lokasi;
      //     this.formJabatan.lokasi_wilayah = data.lokasi_wilayah;
      //     this.formJabatan.besaran_upah_id = data.besaran_upah_id;
      //     this.formJabatan.uraian_besaran_upah = data.uraian_besaran_upah;
      // },
  
      selectDataLampiran : function(data){
          this.formLampiran.id = data.id;
          this.formLampiran.biodata_id = data.biodata_id;
          this.formLampiran.uraian = data.uraian;;
          this.formLampiran.file = data.file;
          this.formLampiran.file_type = data.file_type;
          this.file_old = data.file;
      },
  
      terima_data : function() {
        var formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("no_pendaftaran", this.form.no_pendaftaran);
        formData.append("email", this.form.email);
        formData.append("nohp", this.form.hp);
        formData.append("token", 'lajsodiaunsoiuaqz');
  
        fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "terima_data", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.kirimWa(this.form.hp, 'lajsodiaunsoiuaqz', this.form.id)
            this.Notify('Sukses Verifikasi Data', 'green', 'check_circle_outline');
            this.getView();
        });
      },
      
      kirimWa : function(hp, token, id) {
        fetch(this.$store.state.url.URL_WA + "test", {
        method: "POST",
            headers: {
              "content-type": "application/json",
              // authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              no_hp : hp,
              token : token,
              id : id,
            })
        }).then(res_data => {
            
        });
      },
  
      tolak_data : function() {
        var formData = new FormData();
        formData.append("id", this.form.id);
        formData.append("keterangan", this.form.keterangan);
  
        fetch(this.$store.state.url.URL_BIO_KETERANGAN_UMUM + "tolak_data", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.kirimWaPenolakan(this.form.hp, 'lajsodiaunsoiuaqz', this.form.id)
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },

      kirimWaPenolakan : function(hp, token, id) {
        fetch(this.$store.state.url.URL_WA + "penolakan", {
        method: "POST",
            headers: {
              "content-type": "application/json",
              // authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              no_hp : hp,
              token : token,
              id : id,
              pesan : this.form.keterangan
            })
        }).then(res_data => {

        });
      },
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
          indexing : function(index){
              var idx = ((this.page_first-1)*this.page_limit)+index
              return idx;
          },
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },
  
  
      // ====================================== PAGINATE ====================================
  
    },
  
    mounted () {
      DATA_MASTER.getAgama();
      DATA_MASTER.getStatusPerkawinan();
      DATA_MASTER.getKecamatan();
      DATA_MASTER.getBahasa();
      this.getView();
    },
  }
  