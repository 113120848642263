import { render, staticRenderFns } from "./verifikasiUsulan.vue?vue&type=template&id=3ebd49b9&"
import script from "./verifikasiUsulan.vue?vue&type=script&lang=js&"
export * from "./verifikasiUsulan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QImg,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QImg,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
